import jQuery from 'jquery';
import 'bootstrap';
import './styles.scss';

// Enable Bootstrap tooltips and popovers everywhere
jQuery(function ($) {
  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover();

  $('#get_involved_button').on('click', function (event) {
    $('html, body').animate({ scrollTop: $(document).height() }, 500);
    setTimeout(() => $('#get_involved_email').focus(), 100);
  });

  $('form#get_involved').on('submit', function (event) {
    $('#signup_button').attr('class', 'col-12 btn btn-dark mt-3');
    $('#signup_button').prop('disabled', true);
    $('#signup_button').html(
      '<strong>Signing up. Please wait a few seconds...</strong>'
    );
  });
});
